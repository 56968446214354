import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../reducers";
import {ErrorActions} from "../actions";
import {ErrorModel} from "../../../models/interfaces/error-model";

@Injectable({
    providedIn: 'root'
})
export class ErrorFacade {

    constructor(
        private readonly store: Store<AppState>
    ) {
    }

    onShowError(error: ErrorModel): void {
        this.store.dispatch(ErrorActions.otherError({error}));
    }
}
